<template>
  <div class="st-pusher">
    <div class="st-content">
      <div class="st-content-inner">
        <div class="main clearfix" ref="main">
          <TopHeader v-if="!isMobileApp" />
          <TopNav v-if="!isMobileApp && !is404Page && !noBanner && !isFooterPage" />
          <router-view />
          <!-- <FormSubscribe/> -->
          <!-- <Footer /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

const { VUE_APP_CHANNEL_MOBILE_APP } = process.env;

export default {
  name: 'ContentWrapper',
  components: {
    TopHeader: () => import('@/components/content/TopHeader'),
    TopNav: () => import('@/components/content/TopNav'),
    // FormSubscribe: () => import('@/components/content/FormSubscribe'),
    // Footer: () => import("@/components/content/Footer"),
  },
  props: {
    msg: String,
  },
  computed: {
    ...mapGetters({
      typeChannel: 'GET_TYPE_CHANNEL',
      currentPage: 'GET_CURRENT_PAGE',
    }),
    isMobileApp() {
      return this.typeChannel?.toLowerCase() === VUE_APP_CHANNEL_MOBILE_APP?.toLowerCase();
    },
    is404Page() {
      return this.typeChannel?.toLowerCase() === '404-page';
    },
    isFooterPage() {
      return this.currentPage === 'footer';
    },
    noBanner() {
      return Boolean(this.$route.query.afterBook);
    },
  },
};
</script>
